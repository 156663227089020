<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

// import Transaction from "@/components/widgets/transaction";
import appConfig from "@/app.config";

/**
 * Products-order component
 */
export default {
  page: {
    title: "Orders",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Orders",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
      orders: [],
      sorts: [
        {
          id: 1,
          name: "رقم الطلب - تصاعديا",
          name_en: "order ID - ASC",
          key: "id",
          type: "ASC",
        },
        {
          id: 2,
          name: "رقم الطلب - تنازليا",
          name_en: "order ID - DESC",
          key: "id",
          type: "DESC",
        },
        {
          id: 3,
          name: "إجمالي الطلب - تصاعديا",
          name_en: "Total - ASC",
          key: "order_price",
          type: "ASC",
        },
        {
          id: 4,
          name: "إجمالي الطلب - تنازليا",
          name_en: "Total - DESC",
          key: "order_price",
          type: "DESC",
        },
        {
          id: 5,
          name: "تاريخ الطلب - تصاعديا",
          name_en: "order Date - ASC",
          key: "created",
          type: "ASC",
        },
        {
          id: 6,
          name: "تاريخ الطلب - تنازليا",
          name_en: "order Date - DESC",
          key: "created",
          type: "DESC",
        },
        {
          id: 7,
          name: "تاريخ آخر تحديث - تصاعديا",
          name_en: "order update - ASC",
          key: "updated",
          type: "ASC",
        },
        {
          id: 8,
          name: "تاريخ آخر تحديث - تنازليا",
          name_en: "order update - DESC",
          key: "updated",
          type: "DESC",
        },
      ],
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fab fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fab fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fab fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fab fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
        {
          id: "#SK2546",
          name: "William Cruz",
          date: "03 Oct, 2019",
          total: "$374",
          status: "Paid",
          payment: ["fas fa-money-bill-alt", "COD"],
          index: 7,
        },
        {
          id: "#SK2547",
          name: "Dustin Moser",
          date: "02 Oct, 2019",
          total: "$350",
          status: "Paid",
          payment: ["fab fa-cc-mastercard", "Mastercard"],
          index: 8,
        },
        {
          id: "#SK2548",
          name: "Clark Benson",
          date: "01 Oct, 2019",
          total: "$345",
          status: "Refund",
          payment: ["fab fa-cc-visa", "Visa"],
          index: 9,
        },
      ],
      conditions: [
        { name_en: "status", name: "الحالة", value: 0 },
        { name_en: "sorts", name: "الترتيب", value: 0 },
        { name_en: "between dates", name: "بحث بين تاريخين", value: 0 },
      ],
      ordersStatus: [],
      allStatus: [],
      selectedOrder: {},
      limit: 10,
      page: 1,
      tot_pages: 0,
      searchModel: "",
      loading: false,
      accepting: false,
      rejecting: false,
    };
  },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    search() {
      if (this.searchModel == "") {
        return this.get(this.page);
      }
      this.loading = true;
      this.http
        .post("orders/search", { search: this.searchModel, type: "new" })
        .then((res) => {
          this.loading = false;
          if (res.status) {
            this.orders = res.data;
          }
        });
    },
    bindOrder(order) {
      this.selectedOrder = order;
      console.log(this.selectedOrder);
    },
    acceptOrder(order) {
      order.accepting = true;
      this.http.put("orders", order.id, { is_accepted: true }).then((res) => {
        order.accepting = false;
        if (res.status) {
          this.socket.emit("order_status_change", order);
          order.is_accepted = true;
        }
      });
    },
    returnOrder() {},
    rejectOrder(order) {
      var data = {
        title: "popups.reject",
        msg: "popups.reject_msg",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          order.rejecting = true;
          console.log("###");
          this.http.delete("orders", order.id).then((res) => {
            order.rejecting = false;
            if (res.status) {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تم رفض الطلب", " تم ", "success");
              } else {
                this.responseAlert("Order rejected", "done", "success");
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("حدث خطأ اثناء رفض الطلب", "خطأ", "warning");
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }
            this.get(this.page);
          });
        }
      });
    },
    getOrdersStatus() {
      this.http.get("orders-status").then((res) => {
        if (res.status) {
          this.ordersStatus = [];
          res.data.forEach((status) => {
            if (status?.id == 6) {
              status.status = "Send To Drivers";
              status.status_ar = "إرسال إالى المناديب";
            }
            console.log(status);
            if (
              status?.id == 4 ||
              status?.id == 5 ||
              status?.id == 6 ||
              status?.id == 13
            ) {
              this.ordersStatus.push(status);
            }
          });
          this.allStatus = res.data;
        }
      });
    },
    changeOrderStatus() {
      // this.loading = true;
      // this.http
      //   .put("orders", this.selectedOrder?.id, {
      //     status_id: this.selectedOrder?.status_id,
      //   })
      //   .then((res) => {
      //     this.loading = false;
      //     if (res.status) {
      //       if (this.selectedOrder?.status_id == 6) {
      //         this.socket.emit("Waitting Offers", this.selectedOrder?.id);
      //         console.log("done");
      //       }
      //       if (this.$i18n.locale == "ar") {
      //         this.responseAlert("تم تعديل الحالة بنجاح", " تم ", "success");
      //       } else {
      //         this.responseAlert(
      //           "Status has been edited successfully",
      //           "done",
      //           "success"
      //         );
      //       }
      //       this.get(this.page);
      //     } else {
      //       if (this.$i18n.locale == "ar") {
      //         this.responseAlert(
      //           "حدث خطأ اثناء تعديل الحالة",
      //           "خطأ",
      //           "warning"
      //         );
      //       } else {
      //         this.responseAlert("something went wrong", "error", "warning");
      //       }
      //     }
      //   });

      console.log(this.selectedOrder);
      this.socket.emit("order_status_change", this.selectedOrder);
    },

    get(page) {
      this.loading = true;
      this.http
        .post("orders/vendor/paginate", {
          limit: this.limit,
          page,
          type: "new",
        })
        .then((res) => {
          this.loading = false;
          if (res.status) {
            this.orders = res.data;
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
          }
        });
    },
  },
  created() {
    this.get(1);
    this.getOrdersStatus();
    this.socket.on("orders:update_status", (order) => {
      console.log("goat data", order);
      if (this.$i18n.locale == "ar") {
        this.responseAlert("تم تعديل الحالة بنجاح", " تم ", "success");
      } else {
        this.responseAlert(
          "Status has been edited successfully",
          "done",
          "success"
        );
      }
      let newStatus = {};
      this.allStatus.forEach((status) => {
        if (order?.status_id == status?.id) {
          newStatus.name = status.status_ar;
          newStatus.name_en = status.status;
        }
      });
      this.orders.forEach((item) => {
        if (item?.id == order?.id) {
          item.status_ar = newStatus?.name;
          item.status = newStatus?.name_en;
        }
      });
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="$t('menu.menuitems.orders.sub.new_orders')"
      :items="items"
    />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      v-model="searchModel"
                      type="text"
                      class="form-control"
                      :placeholder="$t('popups.search')"
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-8"></div>
              <!-- end col-->
            </div>
            <!-- Table data -->
            <div class="table-responsive">
              <table
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                class="table table-centered table-nowrap table-striped table-hover align-middle"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <th scope="col">{{ $t("orders.id") }}</th>
                    <th scope="col">{{ $t("orders.order_date") }}</th>
                    <th scope="col">{{ $t("orders.status") }}</th>
                    <th scope="col">{{ $t("orders.total") }}</th>
                    <th scope="col">{{ $t("orders.customer.name") }}</th>
                    <th scope="col">{{ $t("orders.customer.img") }}</th>
                    <th scope="col">{{ $t("orders.customer.phone_email") }}</th>
                    <th scope="col">{{ $t("orders.customer.msg") }}</th>
                    <th scope="col">{{ $t("orders.operations") }}</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(app, index) in orders" :key="app">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <router-link
                        :to="'/new-orders/order-deatiles/' + app?.id"
                      >
                        # {{ app.id }}</router-link
                      >
                    </td>
                    <td>{{ app?.created?.split("T")[0] }}</td>
                    <td>
                      {{ $i18n.locale == "ar" ? app?.status_ar : app?.status }}
                    </td>
                    <td>{{ parseFloat(app?.order_price).toLocaleString() }}</td>
                    <td>
                      <router-link
                        :to="'/new-orders/order-deatiles/' + app?.id"
                      >
                        {{ `${app?.first_name} ${app?.last_name}` }}
                      </router-link>
                    </td>
                    <td>
                      <router-link
                        :to="'/new-orders/order-deatiles/' + app?.id"
                      >
                        <img
                          :src="
                            $store.state?.auth?.mediaUrl +
                            app?.image?.split('public')[1]
                          "
                          style="width: 64px; height: 64px"
                          alt=""
                      /></router-link>
                    </td>
                    <td>
                      <p class="mb-1">{{ app?.tel }}</p>
                      <p class="mb-0">{{ app?.email }}</p>
                    </td>
                    <td>
                      <a
                        role="button"
                        @click="bindOrder(app)"
                        data-bs-toggle="modal"
                        data-bs-target="#msgModal"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="'# ' + app?.id + ' ' + $t('products.descr')"
                      >
                        {{ $t("products.show") }}
                      </a>
                    </td>
                    <td>
                      <button
                        v-if="
                          !app?.accepting &&
                          app?.status_id < 4 &&
                          !app?.is_accepted
                        "
                        class="btn btn-sm btn-success mx-1"
                        @click="acceptOrder(app)"
                        href="javascript: void(0);"
                        role="button"
                      >
                        {{ $t("orders.accept") }}
                      </button>
                      <button
                        v-if="app?.accepting"
                        disabled
                        type="submit"
                        class="btn btn-sm btn-success"
                        style="margin-inline-end: 8px"
                      >
                        <b-spinner small></b-spinner>
                        <span class="sr-only">loading ...</span>
                      </button>
                      <button
                        v-if="
                          !app?.rejecting &&
                          app?.status_id < 4 &&
                          !app?.is_accepted
                        "
                        class="btn btn-sm btn-danger"
                        href="javascript: void(0);"
                        role="button"
                        @click="rejectOrder(app)"
                      >
                        {{ $t("orders.reject") }}
                      </button>
                      <button
                        v-if="app?.rejecting"
                        disabled
                        type="submit"
                        class="btn btn-sm btn-success"
                        style="margin-inline-end: 8px"
                      >
                        <b-spinner small></b-spinner>
                        <span class="sr-only">loading ...</span>
                      </button>
                      <button
                        v-if="app?.is_accepted"
                        data-bs-toggle="modal"
                        data-bs-target="#statusModal"
                        @click="bindOrder(app)"
                        href="#"
                        class="btn btn-primary w-md waves-effect waves-light"
                      >
                        {{ $t("orders.change") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Loader class="mt-4" v-if="loading" :loading="loading" />

            <ul class="pagination pagination-rounded justify-content-end mb-2">
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li
                class="page-item"
                :class="{ active: p == page }"
                v-for="p in tot_pages"
                :key="p"
              >
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(p)"
                  >{{ p }}</a
                >
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <!-- start msg modal -->

    <div
      class="modal fade"
      id="msgModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("orders.order") }} #{{ selectedOrder?.id }}
                {{ $t("orders.msg") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <div class="div">
                <p>{{ selectedOrder?.msg }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end msg modal -->

    <!-- start change status modal -->
    <div
      class="modal fade"
      id="statusModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("orders.change_order") }} #{{ selectedOrder?.id }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <div class="row p-2">
                <label class="inline">{{ $t("orders.change_to") }}</label>
                <select
                  class="mb-3 form-select"
                  v-model="selectedOrder.status_id"
                >
                  <option
                    v-for="status in ordersStatus"
                    :key="status"
                    :selected="status.id == selectedOrder?.status_id"
                    :value="status.id"
                  >
                    {{
                      $i18n.locale == "ar" ? status.status_ar : status.status
                    }}
                  </option>
                </select>
              </div>
            </div>

            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button
                v-if="!loading"
                @click="changeOrderStatus()"
                class="btn btn-success"
              >
                {{ $t("orders.change") }}
              </button>
              <button
                v-if="loading"
                disabled
                type="submit"
                class="btn btn-success"
                style="margin-inline-end: 8px"
              >
                <b-spinner small></b-spinner>
                <span class="sr-only">loading ...</span>
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-secondary"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end change order status modal -->
  </Layout>
</template>

<style scoped>
td {
  padding: 1rem;
}
</style>
